<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-avatar tile>
          <v-img src="@/assets/AdminTools/grid.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Merge Category</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card grid-list-md>
      <ModelTitle title="Merge Category" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-card flat outlined>
              <v-card class="pa-2 px-4" dark flat>
                <v-layout row class="pa-2" align-center>
                  <v-flex> From </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink>
                    <v-checkbox class="mt-0" v-model="deleteFromCategory" hide-details="" label="Delete"></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-select :items="Category" clearable hide-details="selectCategoryError.length === 0"
                      v-model="selectedFromCategory" item-text="name" outlined dense return-object
                      label="Select Category"><template v-slot:append-item>
                      </template></v-select>

                  </v-col>
                  <v-col cols="12" sm="12" v-if="selectedFromCategory && selectedFromCategory.productCount">
                    <div class="text-center text-h4">
                      Total Product: <span class="font-weight-black">{{ selectedFromCategory.productCount }}</span> 
                    </div>
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="text-center">
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-col>
          <v-col sm="12">
            <v-card flat outlined>
              <v-card class="pa-2 px-4" dark flat>
                <div>To</div>
              </v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-select :items="Category" clearable hide-details="selectCategoryError.length === 0"
                      v-model="selectedToCategory" item-text="name" outlined dense return-object
                      label="Select Category"><template v-slot:append-item>
                      </template></v-select>
                  </v-col>
                  
                  <v-col cols="12" sm="12" v-if="selectedToCategory">
                    <div class="text-center text-h4">
                      Total Product: <span class="font-weight-black">{{ selectedToCategory.productCount }}</span> 
                    </div>
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!-- <MoreImage :imageResult="imageResult" @submit="imageChange" /> -->

        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="text" @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import categoryService from "@/modules/Product/Category/service.js";
import GlobalFunction from "@/mixins/GlobalFunction";

const { validationMixin } = require("vuelidate");
const { required, minLength, minValue } = require("vuelidate/lib/validators");

export default {
  props: {
    createDialog: {
      default: false,
    },
  },
  name: "merge-category",
  data() {
    return {
      deleteFromCategory: true,
      selectedFromCategory: null,
      selectedToCategory: null,
      searchName: null,
      imageResult: null,
      selectedCategory: null,
      loading: false,
      dialog: false,
      trackInventory: false,
      Category: [],
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Category: {
      name: {
        required,
        minLength: minLength(4),
      },
      CategoryId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.Category.name.$dirty) return errors;
      if (!this.$v.Category.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Category.name.minLength)
        errors.push("Field must be at least 4 characters long");
      return errors;
    },
    selectCategoryError() {
      const errors = [];
      if (!this.$v.Category.CategoryId.$dirty) return errors;
      if (!this.$v.Category.CategoryId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Category.CategoryId.minValue)
        errors.push("Pleaes select category");

      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCategory();
      }
    },
    // Category: {
    //   handler(val) {
    //     if (val.trackSerialNumber) {
    //       this.Category.trackInventory = true;
    //       this.trackInventory = true;
    //     } else {
    //       this.trackInventory = false;
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    getCategory() {
      this.loading = true;
      return categoryService.getAll().then((response) => {
        this.loading = false;
        console.log('response', response)
        this.Category = response.data;
        return response;
      });
    },
    close() {
      this.empty();
      this.dialog = false;
    },
    empty() {
      this.selectedFromCategory = null
      this.selectedToCategory = null
      this.Category = []
    },
    submit() {
      if (!this.selectedFromCategory) {
        this.$swal("Source", "Source Category is not selected");
        return false;
      }
      if (!this.selectedToCategory) {
        this.$swal("Target", "Target Category is not selected");
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: `You want to merge ${this.selectedFromCategory.name} to ${this.selectedToCategory.name}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const obj = {
            sourceCategory: this.selectedFromCategory,
            targetCategory: this.selectedToCategory,
            deleteSource: this.deleteFromCategory,
          };
          return categoryService
            .merge(obj)
            .then((result) => {
              if (result.status) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Category was merge",
                  });
                this.empty();
                this.loading = false;
                this.close();
              }
            })
            .catch((err) => {
              console.log("error", err);
              this.loading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: err.data.message,
                });
            });
        }
      });
    },
  },
};
</script>
