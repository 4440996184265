<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-avatar tile>
          <v-img src="@/assets/AdminTools/new-product.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Merge Product</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card grid-list-md>
      <ModelTitle title="Merge Product" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-card flat outlined>
              <v-card class="pa-2 px-4" dark flat>
                <v-layout row class="pa-2" align-center>
                  <v-flex> From </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink>
                    <v-checkbox
                      class="mt-0"
                      v-model="deleteFromProduct"
                      hide-details=""
                      label="Delete"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="selectedFromProduct"
                      hide-no-data
                      ref="productSearch"
                      outlined
                      prepend-inner-icon="search"
                      rounded
                      dense
                      :loading="loading"
                      auto-select-first
                      single-line
                      hide-details
                      clearable
                      id="productSearch"
                      :items="Products"
                      item-text="name"
                      return-object
                      :label="$t('labels.searchProduct')"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <span>{{ item.localId }} - {{ item.name }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.trackInventory"
                          :color="
                            item.trackSerialNumber
                              ? 'light-blue darken-1'
                              : 'teal darken-1'
                          "
                          dark
                        >
                          <v-avatar
                            left
                            class="light-blue darken-2"
                            v-if="item.trackSerialNumber"
                          >
                            <v-icon small>mdi-barcode-scan</v-icon>
                          </v-avatar>
                          <v-avatar left v-else class="teal darken-3">
                            <v-icon small>mdi-package-variant-closed</v-icon>
                          </v-avatar>
                          <span>{{ item.balance }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-card outlined flat v-if="selectedFromProduct">
                          <v-card class="pa-2" color="green" dark flat>
                            <v-layout row wrap class="pa-3">
                              <v-flex>
                                <div>Balance</div>
                              </v-flex>
                              <v-spacer></v-spacer>
                              <v-flex shrink>
                                <div>ID: {{ selectedFromProduct.localId }}</div>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-card-text>
                            <div class="text-center display-3">
                              <div>{{ selectedFromProduct.balance }}</div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="text-center">
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-col>
          <v-col sm="12">
            <v-card flat outlined>
              <v-card class="pa-2 px-4" dark flat>
                <div>To</div>
              </v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="selectedToProduct"
                      hide-no-data
                      ref="productSearch"
                      outlined
                      prepend-inner-icon="search"
                      rounded
                      dense
                      :loading="loading"
                      auto-select-first
                      single-line
                      hide-details
                      clearable
                      id="productSearch"
                      :items="Products"
                      item-text="name"
                      return-object
                      :label="$t('labels.searchProduct')"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <span>{{ item.localId }} - {{ item.name }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.trackInventory"
                          :color="
                            item.trackSerialNumber
                              ? 'light-blue darken-1'
                              : 'teal darken-1'
                          "
                          dark
                        >
                          <v-avatar
                            left
                            class="light-blue darken-2"
                            v-if="item.trackSerialNumber"
                          >
                            <v-icon small>mdi-barcode-scan</v-icon>
                          </v-avatar>
                          <v-avatar left v-else class="teal darken-3">
                            <v-icon small>mdi-package-variant-closed</v-icon>
                          </v-avatar>
                          <span>{{ item.balance }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-card outlined flat v-if="selectedToProduct">
                          <v-card class="pa-2" color="green" dark flat>
                            <v-layout row wrap class="pa-3">
                              <v-flex>
                                <div>Balance</div>
                              </v-flex>
                              <v-spacer></v-spacer>
                              <v-flex shrink>
                                <div>ID: {{ selectedToProduct.localId }}</div>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-card-text>
                            <div class="text-center display-3">
                              <div>{{ selectedToProduct.balance }}</div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!-- <MoreImage :imageResult="imageResult" @submit="imageChange" /> -->

        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="text" @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import productService from "../service.js";
import GlobalFunction from "@/mixins/GlobalFunction";

const { validationMixin } = require("vuelidate");
const { required, minLength, minValue } = require("vuelidate/lib/validators");

export default {
  props: {
    createDialog: {
      default: false,
    },
  },
  name: "create-product",
  data() {
    return {
      deleteFromProduct: true,
      selectedFromProduct: null,
      selectedToProduct: null,
      searchName: null,
      imageResult: null,
      selectedCategory: null,
      loading: false,
      dialog: false,
      trackInventory: false,
      Products: [],
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Product: {
      name: {
        required,
        minLength: minLength(4),
      },
      CategoryId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.Product.name.$dirty) return errors;
      if (!this.$v.Product.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.name.minLength)
        errors.push("Field must be at least 4 characters long");
      return errors;
    },
    selectCategoryError() {
      const errors = [];
      if (!this.$v.Product.CategoryId.$dirty) return errors;
      if (!this.$v.Product.CategoryId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.CategoryId.minValue)
        errors.push("Pleaes select category");

      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getProduct();
      }
    },
    Product: {
      handler(val) {
        if (val.trackSerialNumber) {
          this.Product.trackInventory = true;
          this.trackInventory = true;
        } else {
          this.trackInventory = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    getProduct() {
      this.loading = true;
      return productService.getAll({limit:5000}).then((result) => {
        // console.log('result,result', result)
        this.Products = result.data;
        this.Products.map(row => {
          if (row.name === 'Galaxy Buds Fe New') {
            console.log('here', row)
          }
        })
        this.loading = false;
      });
    },
    close() {
      this.empty();
      this.dialog = false;
    },
    empty() {
      this.selectedFromProduct = null
      this.selectedToProduct = null
      this.Product = {
        name: null,
        note: null,
        price: 0,
        cost: 0,
        imageUrl: null,
        CategoryId: null,
        trackSerialNumber: false,
        balance: 0,
        trackInventory: false,
        active: true,
      };
    },
    submit() {
      if (!this.selectedFromProduct) {
        this.$swal("Source", "Source Product is not selected");
        return false;
      }
      if (!this.selectedToProduct) {
        this.$swal("Target", "Target Product is not selected");
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: `You want to merge ${this.selectedFromProduct.name} to ${this.selectedToProduct.name}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const obj = {
            sourceProduct: this.selectedFromProduct,
            targetProduct: this.selectedToProduct,
            deleteSource: this.deleteFromProduct,
          };
          return productService
            .merge(obj)
            .then((result) => {
              if (result.status) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Product was merge",
                  });
                this.empty();
                this.loading = false;
                this.close();
              }
            })
            .catch((err) => {
              console.log("error", err);
              this.loading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: err.data.message,
                });
            });
        }
      });
    },
  },
};
</script>
