<template>
  <v-container grid-list-lg fluid>
    <div class="headline">Admin Tools</div>
    <!-- <v-btn color="success" @click="migration">migration</v-btn> -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-list subheader>
              <v-subheader>User</v-subheader>
              <v-list-item v-if="checkRightStatus(27)" @click="$router.push('/user/create')">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/man.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>New User</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset v-if="checkRightStatus(54)"></v-divider>
              <v-list-item v-if="checkRightStatus(54)" @click="$router.push('/user/list')">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/client.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Existing User</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-subheader>Product</v-subheader>
              <v-list-item @click="setOpenRestockSerial(true)">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/packing.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Restock Product</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-subheader>Invoice</v-subheader>
              <v-list-item v-if="checkRightStatus(55)" @click="$router.push('/invoice/adjustCost')">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/budget.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Adjust Cost Price</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>Product</v-subheader>
              <ProductMerge v-if="checkRightStatus(52)" />
              <CategoryMerge v-if="checkRightStatus(53)" />

              <v-subheader v-if="checkRightStatus(26)">Business</v-subheader>
              <!-- <v-list-item @click="$router.push('/inventory/count')">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/inventoryCount.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Inventory Check</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-divider inset v-if="checkRightStatus(26)"></v-divider>
              <v-list-item v-if="checkRightStatus(26)" @click="$router.push('/setting')">
                <v-list-item-avatar tile>
                  <v-img src="@/assets/AdminTools/gear.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Setting</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import invoiceService from "@/modules/Invoice/service.js";
import ProductMerge from "@/modules/Product/components/Merge.vue";
import CategoryMerge from "@/modules/Product/Category/components/Merge.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ProductMerge,
    CategoryMerge,
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
  },
  methods: {
    ...mapActions("layout", ["setOpenRestockSerial"]),
    async migration() {
      const { value: password } = await this.$swal.fire({
        title: "Enter your password",
        input: "password",
        inputLabel: "Password",
        inputPlaceholder: "Enter your password",
        inputAttributes: {
          maxlength: 10,
          autocapitalize: "off",
          autocorrect: "off",
        },
      });

      if (password === "pak2312141") {
        this.$swal.fire(`Migration Start`);
        await invoiceService.migration({
          BusinessId: 1,
          UserId: 1,
        });

        // console.log('migration', mig)
      }
    },
  },
};
</script>

<style scoped></style>
